import AccountContainer from "@/components/account/account-container";
import LoginForm from "@/components/account/login-form";
import UnauthenticatedGate from "@/components/account/unauthenticated-gate";
import { ClientOnly } from "legend-ui";

export default function LoginPage() {
  return (
    <ClientOnly>
      <UnauthenticatedGate>
        <AccountContainer>
          <LoginForm />
        </AccountContainer>
      </UnauthenticatedGate>
    </ClientOnly>
  );
}
