import { getQueryStringFromPath } from "@/utils/get-query-string-from-path";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useV1ServiceV1AccountOpsGuestLoginCreate,
  useV1ServiceV1AccountOpsGuestWorkspaceLoginCreate,
  useV1ServiceV1UserManagementLoginCreate
} from "common/api/queries";
import Link from "next/link";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import isApiError from "@/utils/api/is-api-error";
import {
  GuestUserLoginWorkspaceNeededResponse,
  UserLoginResponse,
  V1Service
} from "common/api/requests";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import { isGuestUserLoginWorkspaceNeededResponse, isUserLoginResponse } from "@/utils/api/guards";
import { AuthTokens, getSessionFromTokens } from "common/utils/auth";
import { useMetadataStore } from "store/metadata/metadata";
import { Button, Title, Input, ErrorMessage, showToast } from "legend-ui";
import { sendGTMEvent } from "@next/third-parties/google";

const schema = yup.object({
  username: yup.string().trim().required("Username is required"),
  password: yup.string().required("Password is required")
});

export type LoginFormValues = {
  username: string;
  password: string;
};

export default function LoginForm() {
  const router = useRouter();
  const auth = useAuth();
  const next = (router.query?.next ?? "/dashboard?from=login") as string;
  const attemptingCheckoutComplete = router.query.from === "cart";
  const adultUser = !auth?.session || auth?.session?.adultUser;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<LoginFormValues>({ resolver: yupResolver(schema) });
  const queryString = getQueryStringFromPath(router.asPath);
  const [processingGuestLogin, setProcessingGuestLogin] = useState(false);
  const { setWaitingForCheckout } = useMetadataStore();

  const getValidToken = async (): Promise<string> => {
    const session = auth.session;
    if (!session) {
      throw new Error("No session");
    }
    const organizationId = session.organizationId;
    const tokens = organizationId
      ? await V1Service.v1UserManagementTokenOrganizationRefreshCreate({ organizationId })
      : await V1Service.v1UserManagementTokenRefreshCreate();
    auth.replaceAccessToken(tokens.access_token);
    return tokens.access_token;
  };

  const isGuestUserAccountActive = async (): Promise<boolean> => {
    try {
      const isGuest = auth?.session?.role === "guest";
      const userId = auth?.session?.userId;
      if (!isGuest || !userId) {
        return false;
      }

      const response = await V1Service.v1UserManagementUsersRead({ userId });
      return response.active;
    } catch (error) {
      return false;
    }
  };

  const handleGuestLogin = async (values: LoginFormValues) => {
    try {
      setProcessingGuestLogin(true);
      const isAccountActive = await isGuestUserAccountActive();
      if (!isAccountActive) {
        showToast("Woops, the connection was interrupted during login. Please try again.");
        await auth.logout();
        setProcessingGuestLogin(false);
        return;
      }
      const validToken = await getValidToken();
      const response = await guestLogin({
        data: {
          ...values,
          access_token: validToken
        }
      });
      handleGuestLoginSuccess(validToken, values, response);
    } catch (error) {
      handleGuestLoginError(error);
    }
  };

  const handleLogin = (values: LoginFormValues) => {
    if (auth?.session?.role === "guest") {
      handleGuestLogin(values);
    } else {
      regularLogin({
        data: values
      });
    }
  };

  const handleLoginSuccess = (response: UserLoginResponse) => {
    auth.replaceAuth({
      accessToken: response.access_token,
      refreshToken: response.refresh_token
    });
    sendGTMEvent({
      event: "login",
      value: "login",
    });
    router.push(next);
  };

  const handleLoginError = (error: unknown) => {
    if (isApiError(error)) {
      if (error.status === 403) {
        setError("password", { message: "Incorrect username or password." });
        return;
      }
    }
    showToast("We ran into an unexpected issue. Please try again.");
  };

  const handleGuestLoginError = (error: unknown) => {
    setProcessingGuestLogin(false);
    if (isApiError(error)) {
      if (error.status === 403) {
        setError("password", { message: "Incorrect username or password." });
        return;
      }
    }
    showToast("We ran into an unexpected issue. Please try again after a couple seconds.");
  };

  const markWaitingForCheckout = (tokens: AuthTokens) => {
    if (attemptingCheckoutComplete && !adultUser) {
      const session = getSessionFromTokens(tokens);
      setWaitingForCheckout({
        at: new Date().toISOString(),
        userId: session.userId,
        workspaceId: session.workspaceId
      });
    }
  };

  const handleGuestLoginSuccess = (
    token: string,
    values: LoginFormValues,
    response: UserLoginResponse | GuestUserLoginWorkspaceNeededResponse
  ) => {
    if (isUserLoginResponse(response)) {
      const tokens = {
        accessToken: response.access_token,
        refreshToken: response.refresh_token
      };
      markWaitingForCheckout(tokens);
      auth.replaceAuth(tokens);
      sendGTMEvent({
        event: "login",
        value: "login",
      });
      router.push(next);
      return;
    }
    if (isGuestUserLoginWorkspaceNeededResponse(response) && response.length > 0) {
      const choice = response.find((o) => o.type === "organization") ?? response[0];
      const workspaceId = choice.workspace_id;
      workspaceLogin({
        data: {
          ...values,
          access_token: token
        },
        workspaceId
      });
    }
  };

  const handeWorkspaceLoginSuccess = (response) => {
    const tokens = {
      accessToken: response.access_token,
      refreshToken: response.refresh_token
    };
    markWaitingForCheckout(tokens);
    auth.replaceAuth(tokens);
    sendGTMEvent({
      event: "login",
      value: "login",
    });
    router.push(next);
    return;
  };

  const handleWorkspaceLoginError = () => {
    setProcessingGuestLogin(false);
    showToast("We ran into an unexpected issue. Please try again.");
  };

  const { mutate: regularLogin, isPending: isRegularLoginPending } =
    useV1ServiceV1UserManagementLoginCreate({
      onSuccess: handleLoginSuccess,
      onError: handleLoginError
    });
  const { mutateAsync: guestLogin, isPending: isGuestLoginPending } =
    useV1ServiceV1AccountOpsGuestLoginCreate();
  const { mutate: workspaceLogin, isPending: isWorkspaceLoginPending } =
    useV1ServiceV1AccountOpsGuestWorkspaceLoginCreate({
      onSuccess: handeWorkspaceLoginSuccess,
      onError: handleWorkspaceLoginError
    });

  return (
    <form
      onSubmit={handleSubmit(handleLogin)}
      className="flex flex-grow flex-col gap-y-4 text-center"
    >
      <Title className="text-3xl">Log in</Title>
      <div className="flex flex-col text-left">
        <Input label="Username" placeholder="E.g. johndoe" {...register("username")} />
        {errors.username && <ErrorMessage>{errors.username.message}</ErrorMessage>}
      </div>
      <div className="flex flex-col text-left">
        <Input
          label={"Password"}
          type="password"
          placeholder="Password"
          {...register("password")}
        />
        {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
      </div>
      <div className="flex">
        <Link
          href={`/forgot/password${queryString}`}
          className="cursor-pointer select-none text-sm underline"
        >
          Forgot password?
        </Link>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col items-center py-6 md:py-8">
          <p className="text-sm">Don&apos;t have an account with us?</p>
          <Link href={`/signup${queryString}`}>
            <Button type="button" variant="transparent" className="text-black underline">
              Sign up
            </Button>
          </Link>
        </div>
        <Button
          type="submit"
          className="w-full"
          loading={isRegularLoginPending || processingGuestLogin}
          disabled={isRegularLoginPending || processingGuestLogin}
        >
          Log in
        </Button>
        {processingGuestLogin && (
          <p className="pt-2 text-sm">Getting things ready, this could take a few moments</p>
        )}
      </div>
    </form>
  );
}
