import { getAuth } from "common/utils/auth";
import { useRouter } from "next/router";
import { PropsWithChildren, useEffect } from "react";

export default function UnauthenticatedGate({ children }: PropsWithChildren) {
  const router = useRouter();
  const auth = getAuth();
  const isAuthenticated = auth && auth.role !== "guest";

  useEffect(() => {
    if (isAuthenticated) {
      router.push("/dashboard");
    }
  }, []);

  return isAuthenticated ? null : <>{children}</>;
}
