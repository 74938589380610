import { create } from "zustand";
import { persist } from "zustand/middleware";

export const initialState = {};

type IsoDateString = string;

type WaitingForCheckout = {
  at: IsoDateString;
  workspaceId: string;
  userId: string;
};

type PendingOrder = {
  at: IsoDateString;
  orderId: string;
  workspaceId: string;
};

type UnclaimedRewardPrompt = {
  at: IsoDateString;
  workspaceId: string;
};

export type MetadataState = {
  waitingForCheckout?: WaitingForCheckout;
  pendingOrder?: PendingOrder;
  unclaimedRewardPrompt?: UnclaimedRewardPrompt;
  setWaitingForCheckout: (info: WaitingForCheckout) => void;
  clearWaitingForCheckout: () => void;
  setUnclaimedRewardPrompt: (reward: UnclaimedRewardPrompt) => void;
  clearUnclaimedRewardPrompt: () => void;
  setPendingOrder: (info: PendingOrder) => void;
  clearPendingOrder: () => void;
  clear: () => void;
};

// Store for arbitrary metadata relevant to the current logged in user's experience.
export const useMetadataStore = create<MetadataState>()(
  persist(
    (set) => ({
      ...initialState,
      setWaitingForCheckout: (waitingForCheckout) => set({ waitingForCheckout }),
      clearWaitingForCheckout: () => set({ waitingForCheckout: undefined }),
      setUnclaimedRewardPrompt: (unclaimedRewardPrompt) => set({ unclaimedRewardPrompt }),
      clearUnclaimedRewardPrompt: () => set({ unclaimedRewardPrompt: undefined }),
      setPendingOrder: (pendingOrder) => set({ pendingOrder }),
      clearPendingOrder: () => set({ pendingOrder: undefined }),
      clear: () => set(initialState)
    }),
    {
      name: "metadata-store-persist"
    }
  )
);
