import { PropsWithChildren } from "react";
import Container from "../containers/container";
import NavBar from "../navigation/navbar";
import { cn } from "common/utils";

type Props = {
  innerClassName?: string;
};

export default function AccountContainer({ children, innerClassName }: PropsWithChildren<Props>) {
  return (
    <div className="flex min-h-screen flex-col md:pb-4">
      <NavBar />
      <Container innerClassName={cn("flex flex-grow max-w-2xl pt-8 md:pt-10", innerClassName)}>
        {children}
      </Container>
    </div>
  );
}
