import { GuestUserLoginWorkspaceNeededResponse, UserLoginResponse } from "common/api/requests";

export const isUserLoginResponse = (data: unknown): data is UserLoginResponse => {
  return data && data?.["access_token"] && data?.["refresh_token"];
};

export const isGuestUserLoginWorkspaceNeededResponse = (
  data: unknown
): data is GuestUserLoginWorkspaceNeededResponse[] => {
  return Array.isArray(data) && (data?.length === 0 || (data[0]?.type && data[0].workspace_id));
};
